import { ArrowLeftIcon, Button, FieldError, Label, Typography } from "@hero/krypton"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Card, CardContent, CardFooter } from "../../../../00_shared/components/Card"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../00_shared/components/Select"
import { useAmplitude } from "../../../../00_shared/hooks/useAmplitude.hook"
import { toEuros } from "../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceType } from "../../00_shared/business/IssuingCard"
import { RadioCard } from "../../00_shared/components/RadioCard"
import HeroIcon from "../../00_shared/icons/hero-logo-color.png"
import { useIssuingCardCreationContext } from "../IssuingCardCreationContext"
import { AddAddressCard } from "./components/AddAddressCard"
import { AddAddressModal, AddressFormValues } from "./components/AddAddressModal"
import { useCardConfiguration } from "./useCardConfiguration.hook"

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CreditAccountSelectTrigger = styled(SelectTrigger)`
  position: relative;
  padding-left: 2rem;
  > span {
    text-align: start;
    width: 100%;
    > p {
      display: flex;
      justify-content: space-between;
    }
  }

  &::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(${HeroIcon});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: 0.5rem;
  }
`

const CreditAccountSelectItem = styled(SelectItem)`
  width: 100%;

  > span {
    width: 100%;

    > p {
      display: flex;
      justify-content: space-between;
    }
  }
`

const AccountAmount = styled(Typography).attrs(() => ({
  $variant: "body-4-regular",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
  padding-right: 0.25rem;
`

type Address = {
  address1: string
  zipCode: string
  city: string
  country: string
  address2?: string | undefined
  isBaseAddress: boolean
}

export const ConfigureCard: React.FC = () => {
  const { merchantUsers, businessAccounts, merchant } = useCardConfiguration()
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()
  const { state, setState, handleNextStep, goToPreviousStep, errors } = useIssuingCardCreationContext()
  const [isAddAddressModalOpen, setAddAddressModalOpen] = useState(false)

  // State to track custom (newly added) address and the list of address choices
  const [addresses, setAddresses] = useState<Address[]>([])

  const handleCardholderChange = (index: number) => {
    const fullName = `${merchantUsers[index].firstname} ${merchantUsers[index].lastname}`
    setState((prevState) => ({ ...prevState, cardholderId: merchantUsers[index].id, cardholderFullname: fullName }))
    track("card_user_as_cardholder_selected", { cardType: state.cardDetails.cardType })
  }

  const handleAccountChange = (ledgerId: string) => {
    const selectedAccount = businessAccounts.find((account) => account.ledgerId === ledgerId)
    if (selectedAccount) {
      setState((prevState) => ({
        ...prevState,
        businessAccountId: selectedAccount.ledgerId,
        businessAccountName: selectedAccount.name,
      }))
      track("card_account_associated_with_card_selected", { cardType: state.cardDetails.cardType })
    }
  }

  // Handle address submission and add or update the custom address in the list
  const handleNewAddressSubmit = (newAddress: AddressFormValues) => {
    // If there's already a custom address, replace it; otherwise, add it to the list
    const address = { ...newAddress, isBaseAddress: false }
    setAddresses((prevAddresses) => {
      const existingCustomIndex = prevAddresses.findIndex((addr) => !addr.isBaseAddress)
      if (existingCustomIndex > -1) {
        const updatedAddresses = [...prevAddresses]
        updatedAddresses[existingCustomIndex] = address
        return updatedAddresses
      } else {
        return [...prevAddresses, address]
      }
    })

    // Also update the selected address in the context state
    setState((prevState) => ({
      ...prevState,
      cardDetails: {
        ...prevState.cardDetails,
        address: newAddress,
      },
    }))
  }

  const handleClickNewAddress = () => {
    track("card_create_card_delivery_address_clicked")
    setAddAddressModalOpen(true)
  }

  const isPhysicalCard = state.cardDetails.cardType === IssuanceType.PHYSICAL

  const handleGoBack = () => {
    goToPreviousStep()
    track("card_arrow_previous_card_account_selection_clicked", { cardType: state.cardDetails.cardType })
  }

  useEffect(() => {
    if (merchant && merchant.address) {
      const address = merchant.address
      setAddresses((prevAddresses) => [
        {
          address1: address.line1,
          address2: address.line2,
          zipCode: address.zipCode,
          city: address.city,
          country: address.countryCode,
          isBaseAddress: true,
        },
        ...prevAddresses.slice(1),
      ])
    }
  }, [merchant])

  return (
    <Card $variant="plain">
      <CardContent>
        <FlexContainer $direction="column" $gap="2rem">
          <div>
            <Typography $variant="title-2-bold">{t("issuing.cardHolder.title")}</Typography>
            <RadioContainer>
              {merchantUsers.map((user, index) => (
                <RadioCard
                  key={user.id}
                  label={`${user.firstname} ${user.lastname}`}
                  description={user.email}
                  checked={state.cardholderId === user.id}
                  onChange={() => handleCardholderChange(index)}
                />
              ))}
              <FieldError style={{ position: "relative", top: "-.5rem" }}>{errors.cardholderId}</FieldError>
            </RadioContainer>
          </div>

          {/* Associated Account Section */}
          <div>
            <Typography $variant="title-2-bold">{t("issuing.associatedAccount.title")}</Typography>
            <Select
              error={!!errors.businessAccountId}
              errorMessage={errors.businessAccountId}
              onValueChange={handleAccountChange}
              value={state.businessAccountId}
            >
              <Label>{t("issuing.associatedAccount.label")}</Label>
              <CreditAccountSelectTrigger>
                <SelectValue placeholder={t("issuing.associatedAccount.placeholder")} />
              </CreditAccountSelectTrigger>
              <SelectContent>
                {businessAccounts.map((account) => (
                  <CreditAccountSelectItem key={account.ledgerId} value={account.ledgerId}>
                    <Typography as="span">{account.name}</Typography>
                    <AccountAmount as="span">{toEuros(account.balance)}</AccountAmount>
                  </CreditAccountSelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {/* Delivery Address Section (only for physical cards) */}
          {isPhysicalCard && merchant && (
            <div>
              <Typography $variant="title-2-bold">{t("issuing.deliveryAddress.title")}</Typography>
              <RadioContainer>
                {addresses.map((address, index) => (
                  <RadioCard
                    key={index}
                    label={state.cardholderFullname}
                    description={`${address.address1}, ${address.zipCode}, ${address.city}`}
                    checked={state.cardDetails.address?.address1 === address.address1}
                    onChange={() => {
                      track("card_delivery_address_selected")
                      const { isBaseAddress, ...addressWithoutBase } = address
                      setState((prevState) => ({
                        ...prevState,
                        cardDetails: {
                          ...prevState.cardDetails,
                          address: { ...addressWithoutBase, country: "FRA" }, // later country should be dynamic (do not forget mapping ISO alpha-2 to alpha-3)
                        },
                      }))
                    }}
                  />
                ))}
                <AddAddressCard onNewAddress={handleClickNewAddress} />
                <FieldError style={{ position: "relative", top: "-.5rem" }}>{errors.cardDetails}</FieldError>
              </RadioContainer>
            </div>
          )}
        </FlexContainer>
      </CardContent>

      <CardFooter>
        <FlexItem $grow={1}>
          <FlexContainer $direction="column" $gap="2rem">
            <Button $fullWidth $variant="primary" size="medium" onClick={handleNextStep}>
              {t("issuing.continue")}
            </Button>
            <FlexItem $alignSelf="center">
              <Button type="button" size="medium" $variant="underline" onClick={handleGoBack}>
                <ArrowLeftIcon />
                {t("issuing.back")}
              </Button>
            </FlexItem>
          </FlexContainer>
        </FlexItem>
      </CardFooter>

      {/* Add Address Modal */}
      <AddAddressModal
        open={isAddAddressModalOpen}
        onClose={() => setAddAddressModalOpen(false)}
        onSubmit={handleNewAddressSubmit}
      />
    </Card>
  )
}
