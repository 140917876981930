import { Button } from "@hero/krypton"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../businessAccount.context"

const Container = styled.div`
  display: inline;
`

const ButtonContainer = styled.div<{ $isBaBannerActive: boolean }>`
  position: absolute;
  top: ${({ $isBaBannerActive }) => ($isBaBannerActive ? "6.75rem" : "2.75rem")};
  right: 3rem;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }

  @media (max-width: 768px) {
    top: ${({ $isBaBannerActive }) => ($isBaBannerActive ? "7.75rem" : "4rem")};
    right: 1rem;
  }
`

export const TransferHistoryRoot = () => {
  const { isBaActive, isBannerActive, navigateToCreateTransfer } = useBusinessAccountContext()
  const { t } = useCommonTranslation()
  return (
    <Container>
      <ButtonContainer $isBaBannerActive={isBannerActive}>
        <Button
          size="small"
          isLoading={false}
          $variant="primary"
          disabled={!isBaActive}
          onClick={navigateToCreateTransfer}
        >
          {t("wire.history.action")}
        </Button>
      </ButtonContainer>
      <Outlet />
    </Container>
  )
}
