import {
  ApolloError,
  FetchResult,
  OperationVariables,
  TypedDocumentNode,
  gql,
  isApolloError,
  useApolloClient,
} from "@apollo/client"
import { useHeroMutation } from "../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { useChallenge2faContext } from "./Challenge2fa.context"

const CHALLENGE_STATUS = gql`
  query check2FAChallengeStatus($challengeId: String!) {
    check2FAChallengeStatus(challengeId: $challengeId) {
      status
    }
  }
`

export const use2faChallenge = <MutationResponse, OurOperationVariables extends OperationVariables>(
  gqlSchema: TypedDocumentNode,
  translations?: Record<string, string>,
) => {
  const {
    closeModal,
    openModal,
    isModalOpen,
    timeRemaining,
    setTimeRemaining,
    setOnAfterClose,
    setPhone,
    setChallengeId,
  } = useChallenge2faContext()

  const client = useApolloClient()

  const [mutationFn, mutationState] = useHeroMutation<MutationResponse, OurOperationVariables>({
    gqlQuerySchema: gqlSchema,
    translations,
  })

  const mutationWith2fa = async (variables: OurOperationVariables): Promise<FetchResult<MutationResponse>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await mutationFn({
          variables,
        })
        return resolve(response as FetchResult<MutationResponse>)
      } catch (e) {
        if (!isApolloError(e as Error)) {
          return reject(e)
        }

        const apolloError = e as ApolloError

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const challengeId: string = apolloError.graphQLErrors[0]?.challengeId

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const challengePhone: string = apolloError.graphQLErrors[0]?.challengePhone

        if (!challengeId) {
          return reject(apolloError)
        }

        setPhone(challengePhone || "")
        setChallengeId(challengeId)
        if (apolloError.message !== "CHALLENGE_REQUIRED") return reject(apolloError.message)

        openModal()

        const polingInterval: NodeJS.Timer = setInterval(
          () =>
            checkIfChallengeIsDone({ challengeId, resolve, reject, polingInterval, gqlSchema, variables: variables }),
          THREE_SECONDS_IN_MS,
        )

        setOnAfterClose(() => {
          clearInterval(polingInterval)
          return reject("2FA_ABORTED")
        })

        startTimer()

        setTimeout(() => {
          clearInterval(polingInterval)
          closeModal()
          return reject("2FA_FAILED")
        }, FIVE_MINUTES_IN_MS)
      }
    })
  }

  const checkIfChallengeIsDone = async <MutationResponse>({
    challengeId,
    resolve,
    reject,
    polingInterval,
    gqlSchema,
    variables,
  }: {
    challengeId: string
    resolve: (value: FetchResult<MutationResponse> | PromiseLike<FetchResult<MutationResponse>>) => void
    reject: (reason?: unknown) => void
    polingInterval: NodeJS.Timeout
    gqlSchema: TypedDocumentNode
    variables: OurOperationVariables
  }) => {
    const { data } = await client.query({
      query: CHALLENGE_STATUS,
      fetchPolicy: "network-only",
      variables: {
        challengeId,
      },
    })

    if (data.check2FAChallengeStatus?.status === "success") {
      try {
        clearInterval(polingInterval)
        const response = await client.mutate<MutationResponse, OurOperationVariables>({
          mutation: gqlSchema,
          variables,
          context: {
            headers: {
              "x-2fa-challenge-id": challengeId,
            },
          },
        })
        closeModal()
        return resolve(response)
      } catch (e) {
        return reject(e)
      }
    }

    if (data.check2FAChallengeStatus?.status === "failed") {
      clearInterval(polingInterval)
      closeModal()
      return reject("2FA_FAILED")
    }
  }

  const startTimer = () => {
    setTimeRemaining(FIVE_MINUTES_IN_MS)

    const timerInterval = setInterval(() => {
      if (timeRemaining <= 0) {
        clearInterval(timerInterval)
        return
      }

      setTimeRemaining(timeRemaining - ONE_SECOND_IN_MS)
    }, ONE_SECOND_IN_MS)
  }

  return { mutationWith2fa, mutationState, isModalOpen }
}

const ONE_SECOND_IN_MS = 1000
const THREE_SECONDS_IN_MS = ONE_SECOND_IN_MS * 3
const FIVE_MINUTES_IN_MS = ONE_SECOND_IN_MS * 60 * 5
