import { gql } from "@apollo/client"
import { use2faChallenge } from "../../../../../Auth/Challenge2fa/use2faChallenge.hooks"

const CREATE_BENEFICIARY = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type Beneficiary = {
  fullName: string
  iban: string
  label: string | null
  bankName?: string
}

type CreateBeneficiaryOutput = {
  success: boolean
  beneficiaryId: string
}

type CreateBeneficiaryInput = {
  input: Beneficiary
}

export const useAddBeneficiary = () => {
  const {
    mutationWith2fa: addBeneficiary,
    mutationState: { data, error, loading },
    isModalOpen,
  } = use2faChallenge<{ createBeneficiary: CreateBeneficiaryOutput }, CreateBeneficiaryInput>(CREATE_BENEFICIARY)

  const mutate = async (input: Beneficiary) => {
    const result = await addBeneficiary({ input })
    return result.data?.createBeneficiary
  }

  return { mutate, loading, error, data, is2faModalOpen: isModalOpen }
}
