import { KryptonProvider, Spinner } from "@hero/krypton"
import { Suspense } from "react"

import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { appRoutes } from "./App.router"
import IntercomProvider from "./IntercomProvider"

export const App = () => {
  const browserRouter = createBrowserRouter(appRoutes)

  return (
    <IntercomProvider>
      <KryptonProvider>
        <Suspense fallback={<Spinner />}>
          {/* <All2faProviders> */}
          <RouterProvider router={browserRouter} />
          {/* </All2faProviders> */}
        </Suspense>
      </KryptonProvider>
    </IntercomProvider>
  )
}
