import { Button, Header, Tab, Tabs, Typography } from "@hero/krypton"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { HEADER_COLORS } from "../../../env_variables"
import { CwtListCards } from "./ListCards/ListCards"
import { CwtListTable } from "./ListTable/ListTable"

export const CwtListScreen = () => {
  const { t } = useDashboardTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const status = searchParams.get("status")
  useEffect(() => {
    if (!status) {
      setSearchParams({ status: "ONGOING" })
    }
  }, [status, setSearchParams])

  return (
    <PageWrapper>
      <Header $colors={HEADER_COLORS} $fixedHeight>
        <Row>
          <Typography $variant="title-3-inter-bold">{t("creditWireTransfer.list.title")}</Typography>
          <Button<typeof Link> size="small" to="/cwt/application" as={Link} isLoading={false}>
            {t("creditWireTransfer.list.buttons.application")}
          </Button>
        </Row>

        <Tabs>
          <Tab<typeof Link>
            as={Link}
            $isSelected={status === "ONGOING"}
            to="/cwt?status=ONGOING&page=1"
            data-test-id="merchant-ap-balance-ONGOING-tab"
          >
            {t("creditWireTransfer.list.tabs.ongoing")}
          </Tab>
          <Tab<typeof Link>
            as={Link}
            $isSelected={status === "COMPLETED"}
            to="/cwt?status=COMPLETED&page=1"
            data-test-id="merchant-ap-balance-COMPLETED-tab"
          >
            {t("creditWireTransfer.list.tabs.closed")}
          </Tab>
          <Tab<typeof Link>
            as={Link}
            $isSelected={status === "REJECTED"}
            to="/cwt?status=REJECTED&page=1"
            data-test-id="merchant-ap-balance-REJECTED-tab"
          >
            {t("creditWireTransfer.list.tabs.refused")}
          </Tab>
        </Tabs>
      </Header>

      <PageContent>
        {/* hidden in mvp */}
        {false && <CwtListCards />}
        <CwtListTable />
      </PageContent>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 3rem;
`
