import { gradientAnimated, Typography } from "@hero/krypton"
import i18n from "i18next"
import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { HamburgerMenu } from "../00_shared/components/HamburgerMenu"
import { useIsFFBOV3Active } from "../00_shared/hooks/useFeatureFlag.hook"
import useLocalStorage from "../00_shared/hooks/useLocalStorage.hook"
import { TranslationNamespaces, useDashboardTranslation } from "../01_technical/translations"
import HeroIcon from "../assets/logo-hero.png"
import { AuthProvider, useAuthContext } from "../Auth/auth.context"
import { FeatureFlagProvider } from "../FeatureFlags/featureFlags.context"
import { MenuLayoutProvider, useMenuLayoutContext } from "./00_shared/components/MenuLayoutContext"
import { BusinessAccountProvider, useBusinessAccountContext } from "./BusinessAccount/businessAccount.context"
import { DEPRECATED_MENU } from "./DEPRECATED_merchant.menu"
import { Menu } from "./merchant.menu"

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate("/login")
    }
  }, [currentUser, navigate])

  if (currentUser) {
    return children
  } else {
    return null
  }
}

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.main<{ $isMenuReduced: boolean }>`
  margin-left: ${({ $isMenuReduced }) => (!$isMenuReduced ? "15rem" : "4.25rem")};
  transition: margin-left 0.3s;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const MobileHeader = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    padding-top: 0.5rem;
    gap: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const GradientLine = styled.div`
  height: 0.125rem;
  width: 100%;
  ${gradientAnimated}
  &::before {
    width: unset;
  }
`
const Banner = styled.div<{ $isMenuReduced: boolean }>`
  margin-left: ${({ $isMenuReduced }) => (!$isMenuReduced ? "7rem" : "2rem")};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey.$600};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-left: 0;
  }
  position: relative;
`

const MerchantDashboard = () => {
  const isFFBOV3Enabled = useIsFFBOV3Active()
  const [isBOV3, setIsBOV3] = useLocalStorage("BO_V3", !!isFFBOV3Enabled)
  const { isMenuOpen, isMenuReduced } = useMenuLayoutContext()
  const { isBannerActive } = useBusinessAccountContext()
  const { t } = useDashboardTranslation()

  useEffect(() => {
    if (!isFFBOV3Enabled && Boolean(isBOV3)) {
      setIsBOV3(false)
    }
  }, [isBOV3, isFFBOV3Enabled, setIsBOV3])

  if (!isMenuOpen) {
    return <Outlet />
  }

  return (
    <>
      <div>
        {isBOV3 && (
          <div>
            {isBannerActive && (
              <Banner $isMenuReduced={isMenuReduced}>
                <Typography $color="grey.$300" $variant="body-3-regular">
                  {t("businessAccount.inactiveBanner")}
                </Typography>
              </Banner>
            )}

            <GradientLine />
            <MobileHeader>
              <HamburgerMenu />
              <img src={HeroIcon} alt="Hero logo" width={80} />
            </MobileHeader>
          </div>
        )}
        {isBOV3 ? <Menu /> : <DEPRECATED_MENU />}
      </div>
      <Main $isMenuReduced={isMenuReduced}>
        <Outlet />
      </Main>
    </>
  )
}

export const Merchant = () => {
  i18n.setDefaultNamespace(TranslationNamespaces.DASHBOARD)
  i18n.loadNamespaces([TranslationNamespaces.DASHBOARD])

  return (
    <AuthProvider>
      <FeatureFlagProvider>
        <Container data-test-id="dashboard-merchant">
          <ProtectedRoute>
            <MenuLayoutProvider>
              <BusinessAccountProvider>
                <MerchantDashboard />
              </BusinessAccountProvider>
            </MenuLayoutProvider>
          </ProtectedRoute>
        </Container>
      </FeatureFlagProvider>
    </AuthProvider>
  )
}
