import { AddIcon, Button } from "@hero/krypton"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { EmptyState } from "../../../00_shared/components/EmptyState"
import { useAmplitude } from "../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useBusinessAccountContext } from "../../BusinessAccount/businessAccount.context"
import TransferIcon from "../00_shared/icons/transfer-icon.png"
import { useIssuingCardListHook } from "./IssuingCardList.hook"
import { IssuingCardsTable } from "./components/IssuingCardsTable"

const Container = styled.div`
  display: inline;
`

const ButtonContainer = styled.div<{ $isBaBannerActive: boolean }>`
  position: absolute;
  top: ${({ $isBaBannerActive }) => ($isBaBannerActive ? "6.75rem" : "2.75rem")};
  right: 3rem;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }

  @media (max-width: 768px) {
    top: ${({ $isBaBannerActive }) => ($isBaBannerActive ? "7.75rem" : "4rem")};
    right: 1rem;
  }
`

const IssuingCardContainer = ({ children }: { children: React.ReactNode }) => {
  const { t } = useDashboardTranslation()

  return (
    <Container>
      <IssuingCardCreateButton text={t("issuing.card.action.create")} />
      {children}
    </Container>
  )
}

const IssuingCardCreateButton = ({ text }: { text: string }) => {
  const { isBannerActive } = useBusinessAccountContext()
  const navigate = useNavigate()
  const { track } = useAmplitude()

  const handleClickCreateCard = () => {
    track("card_create_card_from_card_page_clicked")
    navigate("/cards/create")
  }

  return (
    <ButtonContainer $isBaBannerActive={isBannerActive}>
      <Button onClick={handleClickCreateCard} size="small" isLoading={false} $variant="primary">
        {text}
      </Button>
    </ButtonContainer>
  )
}

export const IssuingCardList = () => {
  const navigate = useNavigate()
  const { cards, loading } = useIssuingCardListHook()
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()

  const createCards = () => {
    track("card_create_card_from_card_page_clicked")
    navigate("/cards/create")
  }

  if (!loading && !cards.length) {
    return (
      <IssuingCardContainer>
        <EmptyState
          illustration={<img src={TransferIcon} alt="Liste de vos cartes" width={100} />}
          width="22rem"
          title="Vos cartes"
          text="Cet espace vous permet de consulter vos cartes"
          buttonText={t("issuing.card.action.create")}
          buttonIcon={<AddIcon />}
          onButtonClick={createCards}
        />
      </IssuingCardContainer>
    )
  }

  return (
    <IssuingCardContainer>
      <IssuingCardsTable cards={cards} isLoading={loading} />
      <Outlet />
    </IssuingCardContainer>
  )
}
