import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { IssuingCardHeader } from "./IssuingCardHeader"

const LayoutContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
  height: calc(100vh - 0.125rem);
  background-color: ${({ theme }) => theme.colors.white};
`

const MainContent = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  padding: 1.5rem 3rem;

  display: grid;
  grid-template-rows: minmax(28rem, auto);
  min-height: 0;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const IssuingCardsLayout = () => {
  return (
    <LayoutContainer>
      <IssuingCardHeader />
      <MainContent>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  )
}
