import { toaster } from "@hero/krypton"
import { useState } from "react"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { LedgerRegistryType } from "../../../00_shared/business"
import { useUploadFile } from "../../../00_shared/hooks/useUploadFile"
import { useOperationJustificativePresignedUrl } from "../../../pro-account/00_shared/hooks/useOperationJustificativePresignedUrl"
import { useUpdateOperationMetadata } from "../../../pro-account/00_shared/hooks/useUpdateOperationMetadata"
import { useSendBAExternalWire } from "../00_shared/hooks/useSendBAExternalWire"
import { useSendBAInternalWire } from "../00_shared/hooks/useSendBAInternalWire"
import { CreateTransferState, TransferType } from "../CreateTransferContext"

export const useConfirmTransfer = (args: { state: CreateTransferState; clearState: () => void }) => {
  const { state, clearState } = args
  const { loading: loadingInternalWire, mutate: sendInternalWire } = useSendBAInternalWire()
  const { loading: loadingExternalWire, mutate: sendExternalWire } = useSendBAExternalWire()
  const [isTransferSent, setIsTransferSent] = useState(false)
  const [transactionCreatedId, setTransactionCreatedId] = useState<string | null>(null)
  const { fetchSignedUrl } = useOperationJustificativePresignedUrl()
  const { uploadFile } = useUploadFile()
  const { updateOperationMetadata } = useUpdateOperationMetadata()
  const { t } = useCommonTranslation()
  const [recipient] = useState<string>(
    state.selectedTransferType === TransferType.BENEFICIARY ? state.beneficiaryName : state.accountToCreditName,
  )

  const loading = loadingInternalWire || loadingExternalWire
  const transferReference = state.reference || t("transfer.reference.default.value")

  const handleValidateTransfer = async () => {
    try {
      let transactionCreatedId: string
      if (state.selectedTransferType === TransferType.BENEFICIARY) {
        const result = await sendExternalWire({
          amount: state.amount,
          beneficiaryId: state.beneficiaryId,
          businessAccountLedgerId: state.accountToDebitId,
          reference: transferReference,
        })

        if (!result?.transactionId) {
          throw new Error()
        }
        clearState()

        transactionCreatedId = result.transactionId
      } else if (state.selectedTransferType === TransferType.ACCOUNT_TO_ACCOUNT) {
        const { transactionId } = await sendInternalWire({
          variables: {
            input: {
              amount: state.amount,
              destinationLedgerId: state.accountToCreditId,
              businessAccountLedgerId: state.accountToDebitId,
              destinationLedgerType: LedgerRegistryType.BUSINESS_ACCOUNT,
              reference: transferReference,
            },
          },
          onCompleted: () => {
            clearState()
          },
        })
        transactionCreatedId = transactionId
      } else {
        toaster.error(t("internalTransfer.error.default"))
        return
      }
      if (state.justificative) {
        const { signedUrl, path } = await fetchSignedUrl(transactionCreatedId)

        if (!signedUrl) {
          return
        }
        await uploadFile(signedUrl, state.justificative, path)
        const justificativeId = path.split("/").pop()
        if (transactionCreatedId) {
          await updateOperationMetadata({ operationId: transactionCreatedId, metadata: { justificativeId } })
        }
      }
      setTransactionCreatedId(transactionCreatedId)
      setIsTransferSent(true)
    } catch (error) {
      toaster.error(t("internalTransfer.error.default"))
    }
  }

  return {
    handleValidateTransfer,
    isTransferSent,
    transactionCreatedId,
    loading,
    transferReference,
    recipient,
  }
}
