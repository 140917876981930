import { Button, LockIcon } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { IssuanceStatus, IssuanceType, IssuingCard } from "../business/IssuingCard"
import CardPhysicalIllustration from "../icons/card-physical-front.png"
import CardVirtualIllustration from "../icons/card-virtual-front.png"
import IconBlock from "../icons/icon-block.png"
import { ActionCardModal, ModalCardState } from "./ActionCardModal"
import IssuingCardOverlay from "./IssuingCardOverlay"

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-width: 0;
  width: auto;
`

const CardActions = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  gap: 8px;

  & > button div {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
  }
`

const Illustration = styled.div`
  position: relative;
`

interface IssuingCardDisplayWithActionsProps {
  card: IssuingCard
  closeModal?: () => void
}

export const IssuingCardWithActions = ({ card }: IssuingCardDisplayWithActionsProps) => {
  const { t } = useDashboardTranslation()
  const [modalCardState, setModalCardState] = useState<ModalCardState>()

  const handleOpenCardActionModal = async (action: ModalCardState["action"]) => {
    setModalCardState({ isOpen: true, action })
  }

  return (
    <CardContent>
      <Illustration>
        <img
          src={card.issuanceType === IssuanceType.PHYSICAL ? CardPhysicalIllustration : CardVirtualIllustration}
          alt="card"
          width={320}
          style={[IssuanceStatus.TERMINATED, IssuanceStatus.FROZEN].includes(card.status) ? { opacity: 0.5 } : {}}
        />
        <IssuingCardOverlay card={card} />
      </Illustration>

      <CardActions>
        {[IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE].includes(card.status) && (
          <Button size="small" $variant="secondary" onClick={() => handleOpenCardActionModal("freeze")}>
            <LockIcon width={20} />
            {t("issuing.card.actions.freezeCard")}
          </Button>
        )}
        {[IssuanceStatus.FROZEN].includes(card.status) && (
          <Button size="small" $variant="secondary" onClick={() => handleOpenCardActionModal("unfreeze")}>
            <LockIcon width={20} />
            {t("issuing.card.actions.unfreezeCard")}
          </Button>
        )}
        {[IssuanceStatus.ACTIVE, IssuanceStatus.PARTIALLY_ACTIVE, IssuanceStatus.FROZEN].includes(card.status) && (
          <Button size="small" $variant="secondary" onClick={() => handleOpenCardActionModal("terminate")}>
            <img width={20} height={20} src={IconBlock} alt="block" />
            {t("issuing.card.action.terminateCard")}
          </Button>
        )}
      </CardActions>
      {modalCardState && (
        <ActionCardModal
          action={modalCardState.action}
          cardId={card.id}
          isOpen={modalCardState.isOpen}
          onClose={() =>
            setModalCardState((prevState) => {
              if (prevState) {
                return { ...prevState, isOpen: false }
              }
            })
          }
        />
      )}
    </CardContent>
  )
}
