import { Button, FieldError, FieldInput, Label, toaster, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { z } from "zod"
import { Card } from "../../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../../00_shared/components/Dialog"
import { FlexContainer, FlexItem } from "../../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../../00_shared/components/ScrollArea"
import { useAmplitude } from "../../../../../00_shared/hooks/useAmplitude.hook"
import { useDashboardTranslation } from "../../../../../01_technical/translations"

const ContentContainer = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
  }
`

const addressSchema = z.object({
  address1: z
    .string()
    .min(1, { message: "Address line 1 is required" })
    .max(255, { message: "Address line 1 cannot exceed 255 characters" })
    .trim(),
  address2: z.string().max(255, { message: "Address line 2 cannot exceed 255 characters" }).trim().optional(),
  address3: z.string().max(255, { message: "Address line 3 cannot exceed 255 characters" }).trim().optional(),
  address4: z.string().max(255, { message: "Address line 4 cannot exceed 255 characters" }).trim().optional(),
  zipCode: z
    .string()
    .min(1, { message: "Zip code is required" })
    .max(32, { message: "Zip code cannot exceed 32 characters" })
    .trim(),
  city: z
    .string()
    .min(1, { message: "City is required" })
    .max(255, { message: "City cannot exceed 255 characters" })
    .trim(),
  region: z.string().max(32, { message: "Region cannot exceed 32 characters" }).trim().optional(),
  country: z
    .string()
    .min(1, { message: "Country is required" })
    .max(3, { message: "Country code cannot exceed 3 characters" })
    .trim()
    .default("FRA"),
})

export type AddressFormValues = z.infer<typeof addressSchema>

interface AddAddressModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (address: AddressFormValues) => void
}

export const AddAddressModal: React.FC<AddAddressModalProps> = ({ open, onClose, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressFormValues>({
    resolver: zodResolver(addressSchema),
  })
  const { t } = useDashboardTranslation()
  const { track } = useAmplitude()

  const handleGoBack = () => {
    track("card_previous_manually_type_card_delivery_address_clicked")
    onClose()
  }

  const handleFormSubmit = async (data: AddressFormValues) => {
    try {
      onSubmit(data)
      onClose()
    } catch (error) {
      toaster.error("Failed to add address")
    }
  }

  return (
    <Dialog open={open} onOpenChange={handleGoBack}>
      <DialogContent $disableAnimations={true} width="100%" height="100%">
        <ScrollArea width="100%">
          <ContentContainer>
            <Card $variant="plain">
              <Typography $variant="title-2-bold">{t("issuing.addAddress.modal.title")}</Typography>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FlexContainer $direction="column">
                  <FlexItem>
                    <Label>{t("issuing.addAddress.modal.addressLine1.label")}</Label>
                    <FieldInput
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.addressLine1.placeholder")}
                      {...register("address1")}
                    />
                    <FieldError>{errors.address1?.message || <>&nbsp;</>}</FieldError>
                  </FlexItem>
                  <FlexItem>
                    <Label>{t("issuing.addAddress.modal.addressLine2.label")}</Label>
                    <FieldInput
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.addressLine2.placeholder")}
                      {...register("address2")}
                    />
                    <FieldError>{errors.address2?.message || <>&nbsp;</>}</FieldError>
                  </FlexItem>
                  <FlexContainer $justify="space-between" $gap=".5rem">
                    <FlexItem $grow={1} style={{ overflowWrap: "break-word", overflow: "auto" }}>
                      <Label>{t("issuing.addAddress.modal.zipCode.label")}</Label>
                      <FieldInput
                        style={{ width: "100%" }}
                        placeholder={t("issuing.addAddress.modal.zipCode.placeholder")}
                        {...register("zipCode")}
                      />
                      <FieldError>{errors.zipCode?.message || <>&nbsp;</>}</FieldError>
                    </FlexItem>
                    <FlexItem $grow={1} style={{ overflowWrap: "break-word", overflow: "auto" }}>
                      <Label>{t("issuing.addAddress.modal.city.label")}</Label>
                      <FieldInput
                        style={{ width: "100%" }}
                        placeholder={t("issuing.addAddress.modal.city.placeholder")}
                        {...register("city")}
                      />
                      <FieldError>{errors.city?.message || <>&nbsp;</>}</FieldError>
                    </FlexItem>
                  </FlexContainer>
                  {/* <div>
                    <Label>{t("issuing.addAddress.modal.country.label")}</Label>
                    <FieldInput
                      style={{ width: "100%" }}
                      placeholder={t("issuing.addAddress.modal.country.placeholder")}
                      {...register("country")}
                    />
                    <FieldError>{errors.country?.message || <>&nbsp;</>}</FieldError>
                  </div> */}
                  <Button $variant="primary" size="medium" type="submit">
                    {t("issuing.addAddress.modal.saveButton.text")}
                  </Button>
                </FlexContainer>
              </form>
            </Card>
          </ContentContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
